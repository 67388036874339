import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"

import pinIcon from "./pin.png"

import FB from "../images/facebook.svg"
import IG from "../images/instagram-white.png"

const imageQuery = graphql`
  query images {
    img1: file(relativePath: { eq: "food/1.jpeg" }) {
      ...image
    }
    img2: file(relativePath: { eq: "food/2.jpeg" }) {
      ...image
    }
    img3: file(relativePath: { eq: "food/3.jpeg" }) {
      ...image
    }
    img4: file(relativePath: { eq: "food/4.jpeg" }) {
      ...image
    }
    img5: file(relativePath: { eq: "food/5.jpeg" }) {
      ...image
    }
    img6: file(relativePath: { eq: "food/6.jpeg" }) {
      ...image
    }
    blazinLogo: file(relativePath: { eq: "blazinhotchicken.png" }) {
      ...image
    }
    wonderLogo: file(relativePath: { eq: "wonder-wings.png" }) {
      ...image
    }
    templeLogo: file(relativePath: { eq: "templeofwings.png" }) {
      ...image
    }
  }
`

const containerStyle = {
  width: "600px",
  height: "500px",
}

const center = {
  lat: 55.7028614,
  lng: 13.198158,
}

const MARKER_POSITION = {
  lat: 55.701377,
  lng: 13.197644,
}

const Footer = ({
  colorClass,
  showBrands,
  footerBackgroundColor = "#fcfcfc",
  hideFooterFeed,
}) => {
  const { originalPath, t } = useI18next()
  const images = useStaticQuery(imageQuery)

  const { isLoaded } = useJsApiLoader({
    id: "7739b13436232fab",
    googleMapsApiKey: "AIzaSyC3cGUb66WPlFHdL7-fNHIpgQNHSWpIcTs",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    //map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <footer style={{ backgroundColor: footerBackgroundColor }}>
      <div
        className={[...[!hideFooterFeed ? ["footerBG"] : []], colorClass].join(
          " "
        )}
      ></div>
      <div className={["footerContainer", colorClass].join(" ")}>
        <div className={"textContainer wrapper"}>
          {showBrands && (
            <div className={"brands"}>
              <Typography variant="h2" className={"title"}>
                <b>
                  {t(
                    "A collection of food brands made for delivery. Available in Lund."
                  )}
                </b>
              </Typography>
              <div className={"info"}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item sm={4} xs={6} className={"logo"}>
                    <div className={"logo"}>
                      <Link
                        className={"blazinCursor"}
                        to={`/blazin-hot-chicken`}
                      >
                        <GatsbyImage
                          image={
                            images.blazinLogo.childImageSharp.gatsbyImageData
                          }
                          className={"img"}
                          alt="Blazin' Hot Chicken logo"
                        />
                      </Link>
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={6} className={"logo"}>
                    <div className={"logo"}>
                      <Link to={`/wonder-wings`} className={"wonderCursor"}>
                        <GatsbyImage
                          image={
                            images.wonderLogo.childImageSharp.gatsbyImageData
                          }
                          className={"img"}
                          alt="Wonder Wings logo"
                        />
                      </Link>
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={6} className={"logo"}>
                    <div className={"logo"}>
                      <Link to={`/temple-of-wings`} className={"templeCursor"}>
                        <GatsbyImage
                          image={
                            images.templeLogo.childImageSharp.gatsbyImageData
                          }
                          className={"img"}
                          alt="Temple of Wings logo"
                        />
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {!hideFooterFeed && (
            <>
              <div className="info addPhoneMargin" style={{ marginBottom: 64 }}>
                <Typography variant="h5" align="center">
                  {t("feed")}
                </Typography>

                <div className="socials">
                  {false && (
                    <a
                      href="https://www.facebook.com/Bite-103660325001159"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={FB}
                        height="32"
                        width="32"
                        alt="facebook logo"
                      ></img>
                    </a>
                  )}
                  <a
                    href="https://www.instagram.com/bitekitchens/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={IG}
                      height="32"
                      width="32"
                      alt="instagram logo"
                    ></img>
                  </a>
                </div>
                <Typography align="center">@bitekitchens</Typography>
              </div>

              <Grid
                container
                justify="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img1.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Hot chicken strips"
                    />
                  </div>
                </Grid>
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img2.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Cheese fries with wings"
                    />
                  </div>
                </Grid>
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img3.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Glazed Asian chicken"
                    />
                  </div>
                </Grid>
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img4.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Glazed Asian chicken"
                    />
                  </div>
                </Grid>
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img5.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Glazed Asian chicken with fries"
                    />
                  </div>
                </Grid>
                <Grid item sm={2} xs={6} className={"logo"}>
                  <div className={"logo"}>
                    <GatsbyImage
                      image={images.img6.childImageSharp.gatsbyImageData}
                      className={"img"}
                      alt="Glazed Asian chicken with fries"
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={10}
            style={{ marginTop: 128 }}
          >
            <Grid item>
              <div style={{ overflow: "hidden", borderRadius: 6 }} id="contact">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    center={center}
                    zoom={16}
                    options={{
                      disableDefaultUI: true,
                      mapId: "7739b13436232fab",
                      center: center,
                      zoom: 16,
                    }}
                  >
                    <Marker position={MARKER_POSITION} icon={pinIcon} />
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
            <Grid item>
              <div className={"contact"} style={{ textAlign: "center" }}>
                <Typography style={{ fontFamily: "'Space Grotesk'" }}>
                  {t("Contact")}
                </Typography>
                <Typography>
                  Mail:{" "}
                  <a href="mailto:kontakt@bitekitchens.com">
                    kontakt@bitekitchens.com
                  </a>
                </Typography>
                <Typography>
                  Tel: <a href="tel:0725622141">072 562 21 41</a>
                </Typography>
                <Typography
                  style={{ fontFamily: "'Space Grotesk'", marginTop: 32 }}
                >
                  {t("Address")}
                </Typography>
                <Typography>Östra Mårtensgatan 2B</Typography>
                <Typography>223 61 Lund</Typography>
                <Typography
                  style={{ fontFamily: "'Space Grotesk'", marginTop: 32 }}
                >
                  {t("Opening hours")}
                </Typography>
                <Typography>{t("Tuesday - Friday")} 11:30 - 13:30 & 17 - 20</Typography>
                <Typography>{t("Saturday - Sunday")} 11:30 - 20</Typography>
              </div>
            </Grid>
          </Grid>
          <div className={"info"}>
            <div className={"links"}>
              <Grid container justify="space-between" alignItems="flex-start">
                {false && (
                  <Grid item className={"linkGroup"}>
                    <p>
                      <Link
                        onClick={() => {
                          window.analytics.track("Restaurant viewed", {
                            restuarant: "Blazin' Hot Chicken",
                            source: "Footer",
                          })
                        }}
                        to={`/blazin-hot-chicken`}
                      >
                        Blazin' Hot Chicken
                      </Link>
                    </p>
                    <p>
                      <Link to={`/temple-of-wings`}>Temple of Wings</Link>
                    </p>
                    <p>
                      <Link to={`/wonder-wings`}>Wonder Wings</Link>
                    </p>
                  </Grid>
                )}
                <Grid item className={"linkGroup"}>
                  <p>
                    <Link to={`/`}>{t("home")}</Link>
                  </p>
                  <p>
                    <Link to={`/tos/`}>Villkor</Link>
                  </p>
                  <p>
                    <Link to={`/privacy-policy/`}>Privacy Policy</Link>
                  </p>
                  <p>© 2022 Peng Kitchens AB</p>
                </Grid>
                <Grid item className={"linkGroup"}>
                  <p>
                    <a href="https://www.facebook.com/Bite-103660325001159">
                      Facebook
                    </a>
                  </p>
                  <p>
                    <a href="https://www.instagram.com/bitekitchens/">
                      Instagram
                    </a>
                  </p>
                </Grid>
                <Grid item className={"linkGroup"}>
                  <p>
                    <Link
                      className={"langLink"}
                      to={originalPath}
                      language={"sv"}
                    >
                      <span>Svenska</span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className={"langLink"}
                      to={originalPath}
                      language={"en"}
                    >
                      <span>English</span>
                    </Link>
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
