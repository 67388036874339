import React, { useState } from "react"

import { Link, useI18next } from "gatsby-plugin-react-i18next"

import { Button } from "@material-ui/core"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import BiteLogo from "../images/bite_black.svg"
import BiteLogoReverse from "../images/bite.svg"
import Stars from "../images/stars.svg"

const Header = ({ reverseHeader }) => {
  const { originalPath, t } = useI18next()
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        className={reverseHeader ? "reverse" : ""}
        style={{
          backgroundColor: "#212322",
          top: 0,
          left: 0,
          right: 0,
          paddingTop: 8,
          paddingBottom: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "white", marginRight: 8 }}>4.85 / 5</span>
        <img src={Stars} height="14" alt="bite logo"></img>
        <span style={{ color: "white", marginLeft: 16 }}>
          850+ {t("reviews")}
        </span>
      </div>
      <div
        className={["hidedesktop", reverseHeader ? "reverse" : ""].join(" ")}
      >
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} t={t} originalPath={originalPath} />
      </div>
      <header className={["header", reverseHeader ? "reverse" : ""].join(" ")}>
        <div className={"container"}>
          <div className={"headerInner"}>
            <nav className={"menu"}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Link
                  className={"logo headerLink"}
                  style={{ marginLeft: 0 }}
                  to="/"
                >
                  <img
                    src={reverseHeader ? BiteLogoReverse : BiteLogo}
                    height="42"
                    alt="bite logo"
                  ></img>
                </Link>
                <div className="hidemobile">
                  <AnchorLink className={"headerLink"} to={"/#about"}>
                    <span style={{ lineHeight: "normal" }}>
                      {t("Hur funkar det?")}
                    </span>
                  </AnchorLink>
                  <Link
                    className={"headerLink"}
                    to={"/giftcard"}
                    activeStyle={{ textDecoration: "underline" }}
                  >
                    <span style={{ lineHeight: "normal" }}>
                      {t("Köp presentkort")}
                    </span>
                  </Link>
                  <AnchorLink className={"headerLink"} to={"#contact"}>
                    <span style={{ lineHeight: "normal" }}>{t("Kontakt")}</span>
                  </AnchorLink>
                  <Link
                    className={"headerLink"}
                    to={"/catering/"}
                    activeStyle={{ textDecoration: "underline" }}
                  >
                    <span style={{ lineHeight: "normal" }}>
                      {t("Catering")}
                    </span>
                  </Link>
                </div>
              </div>

              <div className="hidemobile">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {false && (
                    <Link
                      className={"hidemobile headerLink "}
                      to={"/order"}
                      language={"sv"}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          borderRadius: 100,
                          textTransform: "none",
                          fontSize: 14,
                          color: reverseHeader ? "white" : "initial",
                          borderColor: reverseHeader ? "white" : "initial",
                        }}
                      >
                        {t("order takeaway")}
                      </Button>
                    </Link>
                  )}
                  <Link
                    className={[
                      "langLink",
                      reverseHeader ? "reverse" : "",
                    ].join(" ")}
                    to={originalPath}
                    language={"sv"}
                    activeStyle={{ textDecoration: "underline" }}
                  >
                    <span>sv</span>
                  </Link>
                  <span
                    className={[
                      "langSeperator",
                      reverseHeader ? "reverse" : "",
                    ].join(" ")}
                  >
                    /
                  </span>
                  <Link
                    className={[
                      "langLink",
                      reverseHeader ? "reverse" : "",
                    ].join(" ")}
                    to={originalPath}
                    language={"en"}
                    activeStyle={{ textDecoration: "underline" }}
                  >
                    <span>en</span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

const Burger = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false

  return (
    <div
      className={["burger", open ? "open" : "closed"].join(" ")}
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
      style={{
        position: open ? "fixed" : "absolute",
      }}
    >
      <span
        style={{
          transform: open ? "rotate(45deg)" : "rotate(0)",
        }}
      />
      <span
        style={{
          transform: open ? "rotate(20deg)" : "rotate(0)",
          opacity: open ? 0 : 1,
        }}
      />
      <span
        style={{
          transform: open ? "rotate(-45deg)" : "rotate(0)",
        }}
      />
    </div>
  )
}

const Menu = ({ open, t, setOpen, originalPath, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <div
      className="mobileMenu"
      open={open}
      aria-hidden={!isHidden}
      style={{ transform: open ? "translateX(0)" : "translateX(100vw)" }}
      {...props}
    >
      <AnchorLink
        onAnchorLinkClick={() => setOpen(false)}
        className={"headerLink"}
        to={"/#about"}
      >
        <span style={{ lineHeight: "normal" }}>{t("Hur funkar det?")}</span>
      </AnchorLink>
      <Link
        className={"headerLink"}
        to={"/giftcard"}
        activeStyle={{ textDecoration: "underline" }}
      >
        <span style={{ lineHeight: "normal" }}>{t("Köp presentkort")}</span>
      </Link>
      <AnchorLink
        onAnchorLinkClick={() => setOpen(false)}
        className={"headerLink"}
        to={"#contact"}
      >
        <span style={{ lineHeight: "normal" }}>{t("Kontakt")}</span>
      </AnchorLink>
      <Link
        className={"headerLink"}
        to={"/catering/"}
        activeStyle={{ textDecoration: "underline" }}
      >
        <span style={{ lineHeight: "normal" }}>{t("Catering")}</span>
      </Link>
      {false && (
        <Link className={"headerLink"} to={"/order"} language={"sv"}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              borderRadius: 100,
              textTransform: "none",
              fontSize: 14,
              color: "white",
              borderColor: "white",
            }}
          >
            {t("order takeaway")}
          </Button>
        </Link>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          className={["langLink", "reverse"].join(" ")}
          to={originalPath}
          language={"sv"}
          activeStyle={{ textDecoration: "underline" }}
        >
          <span>sv</span>
        </Link>
        <span className={["langSeperator", "reverse"].join(" ")}>/</span>
        <Link
          className={["langLink", "reverse"].join(" ")}
          to={originalPath}
          language={"en"}
          activeStyle={{ textDecoration: "underline" }}
        >
          <span>en</span>
        </Link>
      </div>
    </div>
  )
}

export default Header
