/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./Header"
import Footer from "./Footer"

import "./css/layout.css"
import "./css/header.css"
import "./css/footer.css"

import { graphql } from "gatsby"

const Layout = ({
  children,
  colorClass,
  showBrands,
  bg,
  footerBackgroundColor,
  reverseHeader,
  hideFooterFeed,
}) => {
  return (
    <div
      style={{
        backgroundColor: bg ? bg : "inherit",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Header reverseHeader={reverseHeader} />
      <main>
        <div className="wrapper">{children}</div>
      </main>
      <Footer
        colorClass={colorClass}
        showBrands={showBrands}
        footerBackgroundColor={footerBackgroundColor}
        hideFooterFeed={hideFooterFeed}
      />
    </div>
  )
}

export const query = graphql`
  fragment image on File {
    childImageSharp {
      gatsbyImageData(width: 350, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  fragment app on File {
    childImageSharp {
      gatsbyImageData(height: 1300, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  fragment adventure on File {
    childImageSharp {
      gatsbyImageData(width: 1500, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  fragment adventure2 on File {
    childImageSharp {
      gatsbyImageData(width: 1000, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  fragment smallLogo on File {
    childImageSharp {
      gatsbyImageData(width: 350, placeholder: NONE, layout: CONSTRAINED)
    }
  }
`

export default Layout
